import { defineStore } from "pinia";

export const useAuthenticationStore = defineStore("authentication", {
  state: () => ({
    isAuthenticated: false,
    bearerToken: "",
    userRole: "",
    userName: "",
  }),
  actions: {
    setAuthentication(status, token, userRole, userName) {
      this.isAuthenticated = status;
      this.bearerToken = token;
      this.userRole = userRole;
      this.userName = userName;
    },
  },
});
