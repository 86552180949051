import { createApp, watch } from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import { createPinia } from "pinia";
import { loadFonts } from "./plugins/webfontloader";
import { jwtInterceptor } from "@/router/jwt_interceptor";
loadFonts();

const app = createApp(App);
const pinia = createPinia();

const savedState = localStorage.getItem("piniaState");
if (savedState) {
  pinia.state.value = JSON.parse(savedState);
}

watch(
  pinia.state,
  (state) => {
    localStorage.setItem("piniaState", JSON.stringify(state));
  },
  { deep: true }
);

app.use(pinia);
jwtInterceptor();
app.use(router);
app.use(vuetify);
app.mount("#app");
