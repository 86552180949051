<template>
  <v-row class="mx-5" no-gutters="">
    <v-col>
      <v-row justify="start" align="center" no-gutters>
        <h1 class="mt-5">
          <span class="clock-font-size font-weight-regular">{{
            formatHourMinuteTime
          }}</span>
          <span class="date-second-font-size font-weight-regular">{{
            formatSeconds
          }}</span>
        </h1>
      </v-row>
      <v-row class="date-second-font-size font-weight-regular" no-gutters>
        {{ formatCurrentDate }}
      </v-row>
    </v-col>
  </v-row>
</template>

<style scoped>
/* Default styles */
.clock-font-size {
  font-size: 12rem;
  line-height: 1;
}

.date-second-font-size {
  font-size: 3.75rem;
}

/* Adjustments for high-resolution screens */
@media screen and (resolution: 192dpi),
  screen and (-webkit-min-device-pixel-ratio: 2) {
  .clock-font-size {
    font-size: 9rem;
  }
  .date-second-font-size {
    font-size: 2.5rem;
  }
}

/* Adjustments for medium-sized screens (tablets) */
@media (max-width: 992px) {
  .clock-font-size {
    font-size: 6rem;
  }
  .date-second-font-size {
    font-size: 2rem;
  }
}

/* Adjustments for small screens (mobile) */
@media (max-width: 600px) {
  .clock-font-size {
    font-size: 4rem;
  }
  .date-second-font-size {
    font-size: 1.5rem;
  }
}

/* Additional tweaks for very small screens */
@media (max-width: 400px) {
  .clock-font-size {
    font-size: 3rem;
  }
  .date-second-font-size {
    font-size: 1rem;
  }
}
</style>

<script>
import { ref } from "vue";
export default {
  name: "TimeComponent",

  setup() {
    const currentTime = ref(new Date());
    return { currentTime };
  },

  computed: {
    formatHourMinuteTime() {
      const hours = this.currentTime.getHours();
      const mins = this.currentTime.getMinutes();
      const time =
        (hours < 10 ? "0" + hours : hours) +
        ":" +
        (mins < 10 ? "0" + mins : mins);
      return time;
    },
    formatSeconds() {
      const secs = this.currentTime.getSeconds();
      return secs < 10 ? "0" + secs : secs;
    },
    formatCurrentDate() {
      return this.currentTime.toLocaleDateString("nl-NL", {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    },
  },

  created() {
    setInterval(() => {
      this.currentTime = new Date();
    }, 1000);
  },
  beforeUnmount() {
    clearInterval(this.timer);
  },
};
</script>
