import axios from "axios";

import { useAuthenticationStore } from "@/stores/auth_store";

export function jwtInterceptor() {
  var store = useAuthenticationStore();

  axios.interceptors.request.use((request) => {
    const isApiUrl = request.url.startsWith(process.env.VUE_APP_API_BASE_URL);
    if (store.bearerToken != "" && isApiUrl) {
      request.headers.Authorization = `bearer ${store.bearerToken}`;
    }

    return request;
  });

  axios.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      if (
        !error.response ||
        error.response.status == 401 ||
        error.response.status == 403
      ) {
        localStorage.clear();
        location.reload();
      }

      return Promise.reject(error);
    }
  );
}
