import { createRouter, createWebHistory } from "vue-router";
import DashboardView from "../views/dashboard/DashboardView.vue";
import { useAuthenticationStore } from "../stores/auth_store.js";

const routes = [
  {
    path: "/",
    name: "home",
    component: DashboardView,
    meta: {
      requiresAuth: true,
      roles: ["screen", "employee", "chef", "admin", "superadmin", "owner"],
    },
  },
  {
    path: "/admin",
    name: "admin",
    meta: {
      requiresAuth: true,
      roles: ["chef", "admin", "superadmin", "owner"],
    },
    component: () =>
      import(
        /* webpackChunkName: "loginform" */ "../views/admin/AdminDashboardView.vue"
      ),
  },
  {
    name: "adminLogin",
    path: "/admin/login",
    component: () =>
      import(
        /* webpackChunkName: "adminlogin" */ "../views/admin/LoginForm.vue"
      ),
  },
  {
    name: "adminFood",
    path: "/admin/food",
    meta: {
      requiresAuth: true,
      roles: ["chef", "admin", "superadmin", "owner"],
    },
    component: () =>
      import(
        /* webpackChunkName: "adminfood" */ "../views/admin/AdminFoodView.vue"
      ),
  },
  {
    name: "adminUsers",
    path: "/admin/users",
    meta: { requiresAuth: true, roles: ["superadmin", "owner"] },
    component: () =>
      import(
        /* webpackChunkName: "adminusers" */ "../views/admin/AdminUsersView.vue"
      ),
  },
  {
    name: "adminPlanning",
    path: "/admin/planning",
    meta: { requiresAuth: true, roles: ["admin", "superadmin", "owner"] },
    component: () =>
      import(
        /* webpackChunkName: "adminplanning" */ "../views/admin/AdminPlanningView.vue"
      ),
  },
  {
    name: "adminHelp",
    path: "/admin/help",
    meta: { requiresAuth: true, roles: ["admin", "superadmin", "owner"] },
    component: () =>
      import(
        /* webpackChunkName: "adminplanning" */ "../views/admin/AdminHelpView.vue"
      ),
  },
  {
    name: "adminLogout",
    path: "/admin/login",
    component: () =>
      import(
        /* webpackChunkName: "loginform" */ "../views/admin/LoginForm.vue"
      ),
  },
  {
    name: "oneTimePassword",
    path: "/password-setup/:token",
    component: () =>
      import(
        /* webpackChunkName: "loginform" */ "../views/admin/OneTimePassword.vue"
      ),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to) => {
  const store = useAuthenticationStore();

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.isAuthenticated) {
      return {
        name: "adminLogin",
        query: { redirect: to.fullPath },
      };
    } else if (
      to.name !== "home" &&
      !to.matched.some((record) => record.meta.roles.includes(store.userRole))
    ) {
      return {
        name: "home",
        query: { redirect: to.fullPath },
      };
    } else {
      return;
    }
  } else {
    return;
  }
});

export default router;
