<template>
  <v-card class="d-flex pa-10 mt-5 dinner-card">
    <div class="d-flex flex-column align-center justify-center" v-if="loading">
      <v-progress-circular
        indeterminate
        :size="75"
        :width="7"
        color="#fff"
      ></v-progress-circular>
    </div>
    <div class="d-flex flex-grow-1 align-center justify-center" v-else>
      <v-img
        src="../../../public/img/food.png"
        max-height="150%"
        contain
      ></v-img>
      <div class="flex-grow-1 p-3 ml-5">
        <h2 class="max-lines-3 text-white text-left">
          We eten vandaag: <br />
          <span class="max-lines-1">{{ food }}<br /></span>
          Chef van de dag: {{ chef }}
        </h2>
      </div>
    </div>
  </v-card>
</template>

<style scoped>
.dinner-card {
  border-radius: 10px;
  border: 3px solid white;
  background-color: #20202a;
  height: 15% !important;
  width: 90%;
  height: auto;
}

.max-lines-3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.max-lines-1 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
</style>

<script>
import { ref, onMounted } from "vue";
import axios from "axios";
import formatDateToApi from "@/mixins/functions/formatDateToDateOnly";

export default {
  name: "DinnerComponent",

  setup() {
    const loading = ref(true);
    const error = ref(null);
    const food = ref("Onbekend");
    const chef = ref("Onbekend");

    const fetchFood = async () => {
      try {
        const response = await axios.get(
          process.env.VUE_APP_API_BASE_URL +
            `Food/GetByDay/${formatDateToApi(new Date())}`
        );
        if (response.data["data"]["title"]) {
          food.value = response.data["data"]["title"];
        }
        if (response.data["data"]["chef"]) {
          chef.value = response.data["data"]["chef"];
        }
      } catch (e) {
        error.value = e;
      }
    };

    onMounted(async () => {
      await fetchFood();

      setInterval(async () => {
        await fetchFood();
      }, 1800000);

      loading.value = false;
    });

    return {
      food,
      chef,
      fetchFood,
      loading,
    };
  },
};
</script>
