<template>
  <v-row
    v-if="weatherData"
    justify="space-around"
    no-gutters=""
    align="end"
    class="ml-3 mb-5 flex-none"
  >
    <v-col
      v-for="(forecastData, index) in weatherData"
      :key="index"
      class="pa-4 elevation-6 weather-card border-card w-0"
    >
      <v-row
        class="white--text font-weight-bold display-1 text-h4 pa-1"
        justify="center"
      >
        <span>{{ forecastData.avgTemp.toFixed(0) }}°C</span>
      </v-row>

      <v-img
        :src="`../../../img/weather-icons/${forecastData.icon}@4x.png`"
        alt="Weather icon"
        height="auto"
        max-width="100%"
      ></v-img>

      <p class="white--text text-subtitle-1">
        {{ formatWeatherDate(forecastData) }}
      </p>
    </v-col>
  </v-row>

  <v-row v-else>
    <div class="d-flex flex-column align-center justify-center">
      <v-progress-circular
        indeterminate
        :size="200"
        :width="12"
        color="#ffff"
      ></v-progress-circular>
    </div>
  </v-row>
</template>

<style scoped>
.weather-card {
  background: #20202a;
}

.border-card {
  border: 3px solid white;
}

.border-card:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.border-card:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.border-card:not(:first-child) {
  border-left: none;
}
</style>

<script>
import axios from "axios";
import { ref } from "vue";

export default {
  name: "WeatherComponent",

  setup() {
    var weatherData = ref(null);
    return {
      weatherData,
    };
  },
  methods: {
    formatWeatherDate(forecastData) {
      let options = {
        weekday: "long",
      };

      return new Date(forecastData.date).toLocaleDateString("nl-NL", options);
    },
    async fetchWeather() {
      try {
        const response = await axios.get(
          `https://api.openweathermap.org/data/2.5/forecast?units=metric&lat=52.41758832937018&lon=4.8812302714132345&appid=355841d6359d14067c3550217865c7ae`
        );
        const groupedByDate = this.groupByDate(response.data.list);
        this.weatherData = this.calculateAvgTemp(groupedByDate);
      } catch (error) {
        console.error(error);
      }
    },
    groupByDate(data) {
      return data.reduce((acc, curr) => {
        const date = new Date(curr.dt * 1000).toISOString().split("T")[0];
        if (!acc[date]) {
          acc[date] = [];
        }
        acc[date].push(curr);
        return acc;
      }, {});
    },
    findClosestIcon(data) {
      const currentHour = new Date().getHours();

      const closestHourData = data.reduce((prev, curr) => {
        const hour = new Date(curr.dt * 1000).getHours();
        return Math.abs(hour - currentHour) <
          Math.abs(new Date(prev.dt * 1000).getHours() - currentHour)
          ? curr
          : prev;
      });

      return closestHourData.weather[0].icon;
    },
    calculateAvgTemp(data) {
      return Object.keys(data).map((date) => {
        const temps = data[date].map((item) => item.main.temp);
        const lowestTemp = Math.min(...temps);
        const highestTemp = Math.max(...temps);
        const avgTemp = temps.reduce((a, b) => a + b, 0) / temps.length;
        const icon = this.findClosestIcon(data[date]);
        return { date, lowestTemp, highestTemp, avgTemp, icon };
      });
    },
  },
  created() {
    this.fetchWeather();
    setInterval(() => {
      this.fetchWeather();
    }, 1800000);
  },
};
</script>
