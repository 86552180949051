<template>
  <v-row class="bg-phila h-auto text-white" justify="center" no-gutters>
    <v-col class="d-flex flex-column">
      <TimeComponent></TimeComponent>
      <v-row class="ma-5" no-gutters="">
        <img src="../../../img/phila-text-trans-glow.png" width="600"
      /></v-row>
      <WeatherComponent></WeatherComponent>
    </v-col>
    <v-col
      class="h-screen d-flex flex-column align-center justify-space-between"
    >
      <DinnerComponent></DinnerComponent>
      <ScheduleComponent></ScheduleComponent>
    </v-col>
  </v-row>
</template>

<style scoped>
.bg-phila {
  background-image: url("../../../public/img/bg.jpg");
  background-repeat: repeat;
  background-size: cover;
  background-position: center;
}
</style>

<script>
import TimeComponent from "@/components/Dashboard/TimeComponent.vue";
// import CalendarComponent from "../../components/Dashboard/CalendarComponent.vue";
import ScheduleComponent from "../../components/Dashboard/ScheduleComponent.vue";
import DinnerComponent from "../../components/Dashboard/DinnerComponent.vue";
import WeatherComponent from "../../components/Dashboard/WeatherComponent.vue";

export default {
  name: "DashboardView",
  components: {
    WeatherComponent,
    ScheduleComponent,
    DinnerComponent,
    // CalendarComponent,
    TimeComponent,
  },
};
</script>
