<template>
  <div
    class="h-screen d-flex flex-column align-center justify-center"
    v-if="loading && scheduleData"
  >
    <v-progress-circular
      indeterminate
      :size="200"
      :width="12"
      color="#66368a"
    ></v-progress-circular>
  </div>
  <v-container class="mb-5" v-else>
    <v-row class="header" no-gutters>
      <v-col class="cell">
        <b>Planning</b>
      </v-col>
      <v-col v-for="(shift, index) in shifts" :key="index" class="cell">
        <b>{{ shift.type }}</b>
      </v-col>
    </v-row>
    <v-row
      v-for="(day, dayIndex) in days"
      :key="dayIndex"
      class="day"
      no-gutters
    >
      <v-col class="cell label">
        <b>
          <span class="text-capitalize">{{ day.label }}</span>
          <br />
          {{ formatToDutchDate(day.date) }}
        </b>
      </v-col>
      <v-col
        v-for="(shift, shiftIndex) in shifts"
        :key="shiftIndex"
        class="cell"
      >
        <div
          v-for="(staffMember, staffIndex) in getStaffForShift(day, shift)"
          :key="staffIndex"
          class="staff-member"
        >
          {{ staffMember.user.userName }}
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { ref, computed, onMounted } from "vue";
import axios from "axios";
import formatDateToApi from "@/mixins/functions/formatDateToDateOnly";
import formatToDutchDate from "@/mixins/functions/formatToDutchDate";

export default {
  name: "ScheduleComponent",
  setup() {
    const startDate = ref(new Date()); // Start date of the planning

    const scheduleData = ref(null);
    const loading = ref(true);
    const error = ref(null);

    const shifts = ref(null);

    const days = computed(() => {
      if (scheduleData.value === null) {
        return [];
      }

      const currentDate = new Date(startDate.value);
      const daysArr = [];

      for (let i = 0; i < 7; i++) {
        const day = new Date(currentDate);
        day.setDate(currentDate.getDate() + i);
        const formattedDay = day.toLocaleDateString("nl", {
          weekday: "long",
        });
        const date = day.toISOString().split("T")[0];

        const shiftsForDay = scheduleData.value.filter(
          (shift) => shift.date === date
        );
        daysArr.push({
          label: formattedDay,
          date: date,
          shiftsForDay: shiftsForDay,
        });
      }

      return daysArr;
    });

    const getStaffForShift = (day, shift) => {
      if (day.shiftsForDay == null) {
        return [];
      }

      if (!day || !shift) {
        return [];
      }

      const shiftsForDay = day.shiftsForDay.filter(
        (s) => s.shift.type === shift.type
      );

      if (!shiftsForDay || shiftsForDay.length === 0) {
        return [];
      }
      return shiftsForDay;
    };

    const fetchSchedule = async () => {
      try {
        const response = await axios.get(
          process.env.VUE_APP_API_BASE_URL +
            `Schedule/SevenDaysFrom/${formatDateToApi(startDate.value)}`
        );
        scheduleData.value = response.data["data"];
      } catch (e) {
        error.value = e;
      }
    };

    const fetchShiftTypes = async () => {
      try {
        const response = await axios.get(
          process.env.VUE_APP_API_BASE_URL + `Schedule/ShiftTypes`
        );
        shifts.value = response.data["data"];
      } catch (e) {
        error.value = e;
      }
    };

    onMounted(async () => {
      await fetchShiftTypes();
      await fetchSchedule();

      setInterval(async () => {
        await fetchShiftTypes();
        await fetchSchedule();
      }, 60000);

      loading.value = false;
    });

    return { startDate, days, shifts, getStaffForShift, formatToDutchDate };
  },
};
</script>

<style scoped>
.v-container {
  padding: 0;
  width: 90%;
  height: 75vh;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.header,
.day {
  display: flex;
  width: 100%;
}

.cell {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 3px solid white;
  background-color: #20202a;
  padding: 1em;
  flex-grow: 1;
}

.header .cell:first-child {
  border-top-left-radius: 10px;
}

.header .cell:last-child {
  border-top-right-radius: 10px;
}

.day:last-child .cell:first-child {
  border-bottom-left-radius: 10px;
}

.day:last-child .cell:last-child {
  border-bottom-right-radius: 10px;
}

.header .cell:not(:last-child),
.day .cell:not(:last-child) {
  border-right: none;
}

.day .cell {
  border-top: none;
}
</style>
